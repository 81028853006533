<template>
    <div class="other">
        <h1>Other <br>Products</h1>
        <p>Explore more about our Arcanite products</p>
        <div class="cards">
            <div v-for="i in otherProducts" :key="i">
                <div class="card" @click="goRouter(i.path)">
                    <div class="cardColor">
                        <span class="icons">
                            <img :src="i.icons" alt="" class="icons">
                        </span>
                        <div class="text">
                            <div class="title">
                                {{i.title}}
                            </div>
                            <div class="content">
                                {{i.content}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default ({
    data() {
      return{
          otherProducts:[
            {icons:require("./../../assets/imgs/pc/topNav/p1.png"),
            title:"Arcanite",
            content:"Mobile-focused platform for project marketing professionals",
            path:"/Arcanite"},
          {icons:require("./../../assets/imgs/pc/topNav/p2.png"),
          title:"Arcanite Connect",
          content:"Manage your sales operations from your phone",
          path:"/ArcaniteConnect"},
          {icons:require("./../../assets/imgs/pc/topNav/p3.png"),
          title:"Arcanite Partners",
          content:"Connect developers and agents with industry associates",
          path:"/ArcanitePartners"},
          {icons:require("./../../assets/imgs/pc/topNav/p4.png"),
          title:"DragonVR",
          content:"Bring off-the-plan properties to life",
          path:"/DragonVR"},
        //   {icons:require("./../../assets/imgs/pc/topNav/p5.png"),
        //   title:"Equimate",
        //   content:"Help Aussies buyers get into their dream home",
        //   path:"/Equimate"},
          {icons:require("./../../assets/imgs/pc/topNav/p6.png"),
          title:"Project Marketplace",
          content:"Expand your network and discover new sales opportunities",
          path:"/Marketplace"}],
      }
  },
  methods:{
    goRouter(path){
       this.$router.push(path)
    },
  }
})
</script>
<style lang="scss" scoped>
  h1{
    margin: auto;
    // width: 325px;
    padding-top: 50px;
    font-family: Poppins-Bold;
    font-size: 36px;
    // font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #062440;
  }
  p{
    margin: auto;
    // width: 325px;
    margin-top:20px;
    margin-bottom:44px;
    height: 20px;
    flex-grow: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #314455;
  }
  .cards{
    margin: auto;
    margin-bottom: 50px;
    // width: 325px;
    .card{
        cursor: pointer;
        // width:325px;
        height: 68px;
        // padding:20px;
        margin-bottom: 40px;
        // margin-left:40px;
        // padding: 20px;
        // display: flex;
        text-align: left;
        .cardColor{
            display: flex;
            text-align: left;
            width: 100%;
            height: 100%;
            .icons{
                width: 30px;
                height: 30px;
            }
            .text{
                margin-left:20px;
                // height: 100px;
                .title{
                    margin-bottom: 6px;
                    flex-grow: 0;
                    font-family: Poppins-Bold;
                    font-size: 16px;
                    // font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.38;
                    letter-spacing: normal;
                    text-align: left;
                    color: #062440;
                }
                .content{
                    // width: 300px;
                    flex-grow: 0;
                    font-family: Poppins;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.43;
                    letter-spacing: normal;
                    text-align: left;
                    color: #062440;
                }
            }
        }
    }
  }
    
.other{
    margin:0 26px 0 26px;
}
</style>
